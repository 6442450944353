function Copyright() {
  return (
    <div className="copyright">
      <div className="copyright__text">
        Copyright
        <footer>
          <p>&copy;</p>
        </footer>
        2024 yasaman.dev. All Rights Reserved
      </div>
    </div>
  );
}

export default Copyright;
